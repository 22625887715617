import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import { useLocaleContext } from '../../../context/Locale';
import './style.scss';
import PageMenu from '../../../components/PageMenu/PageMenu';
import homeWarrantyResourcesMenu from '../../../data/page-menus/home-warranty-resources';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../data/common/common';
import Icon, { IconTypes } from '../../../components/Icon/Icon';

type Props = PageProps & WithTranslation;

const MakeAClaim: React.FC<Props> = (props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('makeAClaim.seo.title'),
    },
    page: {
      title: t('makeAClaim.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.FOREST_GREEN,
        heroImage: {
          fileName: 'hero-image-15.jpg',
          alt: 'Image',
        },
      },
    },
  };

  return (
    <Layout options={layoutOptions} className="MakeAClaim">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="MakeAClaim__generic-gap">
              <h2 className="h4">{t('makeAClaim.callEncompass')}</h2>
              <p className="MakeAClaim__info-with-icon">
                <ExternalLink
                  href={`tel:${CommonData.tollFreeCustomerServiceNumber.enCompass}`}
                  aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                    CommonData.tollFreeCustomerServiceNumber.enCompass
                  }`}
                >
                  <Icon type={IconTypes.PHONE} />
                  {CommonData.tollFreeCustomerServiceNumber.enCompass}
                </ExternalLink>
              </p>
            </div>
            <div className="MakeAClaim__generic-gap">
              <h2 className="h4">{t('makeAClaim.orGetMoreInformation')}</h2>
              <p className="MakeAClaim__info-with-icon">
                <ExternalLink href={CommonData.websites.enCompassCertificateLink[activeLocaleTag]}>
                  <Icon type={IconTypes.WORLD} />
                  {CommonData.websites.enCompassCertificateLink[activeLocaleTag]}
                </ExternalLink>
                <br />
                <br />
                <ExternalLink
                  href={`mailto:${t(`${LocaleNameSpaceKeys.COMMON}:emails.encompass`)}`}
                  aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${t(
                    `${LocaleNameSpaceKeys.COMMON}:emails.encompass`
                  )}`}
                >
                  <Icon type={IconTypes.EMAIL_AT_SIGN} />
                  {t(`${LocaleNameSpaceKeys.COMMON}:emails.encompass`)}
                </ExternalLink>
              </p>
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              className="HomeWarranty__generic-gap"
              menu={homeWarrantyResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.homeWarrantyResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(MakeAClaim);
