import RouteKeys from '../../../config/RouteKeys';
import { PageMenuLinks } from './pageMenuTypes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const homeWarrantyResourcesMenu: PageMenuLinks = [
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.MAKE_A_CLAIM),
    textKey: 'pageTitles.makeAClaim',
  },
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.FAQ),
    textKey: 'pageTitles.homeWarrantyFAQ',
  },
];

export default homeWarrantyResourcesMenu;
